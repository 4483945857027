<template>
  <div class="container">
    <!-- Encabezado -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Resumen</h2>
      <div class="col-auto">
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3"
          @click="actualizarConsultas"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          v-if="tieneAccesoACrearPaquetes"
          color="primario"
          icon="plus"
          :disabled="botonDeshabilitado"
          class="my-sm-0 px-3"
          @click="mostrarFormularioPaquete = true"
        >
          Nuevo paquete
        </mdb-btn>
      </div>
    </div>

    <!-- Cartas resumen rápido -->
    <div class="row justify-content-center my-3 my-lg-4">
      <!-- Tarjetas propias del rol Admin + Agencia -->
      <!-- Grupo 1 -->
      <template v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('Agencia')">
        <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3">
          <div class="card-descripcion">
            <header class="titulo">
              <span class="clave">Órdenes</span>
              de compra abiertas
            </header>
            <div class="fila-data">
              <img
                src="/img/iconos-tarjetas-resumen/OrdenesCompraAbiertas.svg"
                alt="Vector para Órdenes de compra abiertas"
              >
              <p :class="{'sin-datos': !(ordenesCompra)}">
                {{ ordenesCompra ? ordenesCompra.length : 'Sin órdenes de compra'}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3">
          <div class="card-descripcion">
            <header class="titulo">
              <span class="clave">Órdenes</span>
              de compra pendientes de pago
            </header>
            <div class="fila-data">
              <img
                src="/img/iconos-tarjetas-resumen/PendientePagoCompra.svg"
                alt="Vector para Compras pendientes de pago"
              >
              <p :class="{'sin-datos': !(resumenRapido
                && (typeof resumenRapido.cantidadOrdenesPendientePago === 'number'))}">
                {{ resumenRapido ? resumenRapido.cantidadOrdenesPendientePago : 'Sin órdenes de compra'}}
              </p>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="rol.includes('Admin') || rol.includes('Agencia')"
        class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Órdenes</span>
            de compra realizadas sin recibir
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/ComprasSinRecibir.svg"
              alt="Vector para Órdenes de compras realizadas sin recibir"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.cantidadOrdenesSinRecibir : 'Sin órdenes de compra'}}
            </p>
          </div>
        </div>
      </div>

      <!-- Grupo 2 -->
      <template v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('Agencia')">
        <div class="w-100" />
        <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3">
          <div class="card-descripcion">
            <header class="titulo">
              <span class="clave">Paquetes</span>
              que no se encuentran pagados
            </header>
            <div class="fila-data">
              <img
                src="/img/iconos-tarjetas-resumen/PendientePagoPaquete.svg"
                alt="Vector para Paquetes pendientes de pago"
              >
              <p :class="{'sin-datos': !(resumenRapido
                && (typeof resumenRapido.cantidadPaquetePendientePago === 'number'))}">
                {{ resumenRapido ? resumenRapido.cantidadPaquetePendientePago : 'Sin paquetes'}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3">
          <div class="card-descripcion">
            <header class="titulo">
              <span class="clave">Paquetes</span>
              sin pago asignado
            </header>
            <div class="fila-data">
              <img
                src="/img/iconos-tarjetas-resumen/PendientePagoPaquete.svg"
                alt="Vector para Paquetes pendientes de pago"
              >
              <p :class="{'sin-datos': !(resumenRapido)}">
                {{ resumenRapido ? resumenRapido.paquetesCantidadPagoPendiente : 'Sin paquetes'}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3">
          <div class="card-descripcion">
            <header class="titulo">
              <span class="clave">Pagos</span>
              por aprobar
            </header>
            <div class="fila-data">
              <img
                src="/img/iconos-tarjetas-resumen/PagosPorAprobar.svg"
                alt="Vector para Paquetes pendientes de pago"
              >
              <p :class="{'sin-datos': !(resumenRapido)}">
                {{ resumenRapido ? resumenRapido.pagosCantidadPorConfirmar : 'Sin pagos'}}
              </p>
            </div>
          </div>
        </div>
        <div class="w-100" />
      </template>

      <!-- Grupo 3 -->
      <div
        v-if="rol.includes('Admin') || rol.includes('OpAlmacen')"
        :class="['col-12 col-sm-6 col-md-4',
          (rol.includes('OpPuerto') && rol.includes('OpAlmacen')) || rol.includes('Admin') ? 'col-xl' : 'col-xl-3',
          'px-1 px-sm-2 py-3'
        ]"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            en Miami
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/EsperaMiami.svg"
              alt="Vector para Paquetes en Miami"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.EsperaMiami : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="rol.includes('Admin') || rol.includes('Agencia') || rol.includes('OpPuerto')"
        class="col-12 col-sm-6 col-md-4 col-xl px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            en Caracas sin entregar
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/EsperaCaracas.svg"
              alt="Vector para Paquetes en Caracas sin entregar"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? paquetesEnCaracas : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('Agencia') || rol.includes('OpAlmacen')"
        class="col-12 col-sm-6 col-md-4 col-xl px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            con instrucciones de envío
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/InstruccionesEnvio.svg"
              alt="Vector para Instrucciones de envío"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.paquetesCantidadInstruccionEnvio : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="rol.includes('Admin') || rol.includes('Agencia') || rol.includes('OpAlmacen')"
        class="col-12 col-sm-6 col-md-4 col-xl px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            sin cliente
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/PaquetesSinCliente.svg"
              alt="Vector para Paquetes sin cliente"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.paquetesCantidadSinCliente : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('OpPuerto')"
        class="col-12 col-sm-6 col-md-4 col-xl px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            aprobados para la entrega
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/PaquetesAprobados.svg"
              alt="Vector para Paquetes aprobados"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.paquetesCantidadAprobadosEntrega : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('Agencia') || rol.includes('OpAlmacen') || rol.includes('OpPuerto')" class="w-100" />

      <!-- Grupo 4 -->
      <div
        v-if="rol.includes('Admin') || rol.includes('Agencia')"
        class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            en tránsito nacional
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/TransitoNacional.svg"
              alt="Vector para Paquetes en tránsito nacional"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.transitoNacional : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="rol.includes('Admin')"
        class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Paquetes</span>
            en tránsito internacional
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/TransitoInternacional.svg"
              alt="Vector para Paquetes en tránsito internacional"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.transitoInternacional : 'Sin paquetes'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('OpAlmacen')"
        class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Consolidados</span>
            cargando
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/Consolidado.svg"
              alt="Vector para Consolidados cargando"
            >
            <p :class="{'sin-datos': !(consolidadosCargando)}">
              {{ consolidadosCargando ? consolidadosCargando.length : 'Sin consolidados'}}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="(rol.includes('Admin') && mostrarTarjetas) || rol.includes('OpPuerto')"
        class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 px-sm-2 py-3"
      >
        <div class="card-descripcion">
          <header class="titulo">
            <span class="clave">Consolidados</span>
            en puerto
          </header>
          <div class="fila-data">
            <img
              src="/img/iconos-tarjetas-resumen/enPuertoAeropuerto.svg"
              alt="Vector para consolidados en puerto"
            >
            <p :class="{'sin-datos': !(resumenRapido)}">
              {{ resumenRapido ? resumenRapido.consolidadosCantidadEnPuerto : 'Sin consolidados'}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <header
      v-if="rol.includes('Admin')"
      class="user-select-none cursor-pointer"
      title="Mostrar cartas ocultas"
      @click="mostrarTarjetas = !mostrarTarjetas"
    >
      <font-awesome-icon
        :icon="mostrarTarjetas ? 'minus-circle' : 'plus-circle'"
        size="sm"
        class="texto-exitoso"
      />
      {{mostrarTarjetas ? 'Mostrar menos' : 'Mostrar más'}}
    </header>
    <!-- Fin cartas resumen rápido -->

    <!-- Nav lista de tarjetas:
        Paquete sin cliente, Consolidados cargando y Órdenes de compra
    -->
    <nav class="tab-lista d-lg-none">
      <a v-if="tieneAccesoAPaquetesSinClientes" href="#paquetes" class="tab-item">
        Paquetes
      </a>
      <a href="#consolidados" class="tab-item">
        Consolidados
      </a>
      <a href="#ordenes" class="tab-item">
        Órdenes
      </a>
    </nav>
    <div class="row justify-content-center my-lg-4 mb-5">
      <div
        v-if="tieneAccesoAPaquetesSinClientes"
        class="col-12 col-sm-10 col-md-7 col-lg-4 my-2 px-2"
      >
        <div id="paquetes" class="columna">
          <header>Paquetes sin cliente</header>
          <CargandoVista
            v-if="$apolloData.queries.paquetesSinCliente.loading"
            clase-adicional="vc-160px mb-3"
          />
          <div
            v-else-if="!$apolloData.queries.paquetesSinCliente.loading
              && !(paquetesSinCliente && paquetesSinCliente.length)"
            class="text-muted text-center user-select-none py-3"
          >
            No hay paquetes sin cliente
          </div>
          <div
            v-else
            v-for="paquete in paquetesSinCliente"
            :key="paquete.id"
            class="card"
          >
            <small>Receptor</small>
            <span :class="{'text-muted user-select-none': !(paquete.receptor && paquete.receptor.length)}">
              {{ paquete.receptor ? paquete.receptor : 'Sin receptor'}}
            </span>
            <div class="row justify-content-center">
              <div
                class="col-6 col-sm-4 col-lg-6 pr-2 cursor-pointer"
                title="Haz clic para ver los detalles del paquete"
                @click="$router.push({ name: 'Paquete', params: { paqueteId: paquete.id } })"
              >
                <small>
                  Paquete ID
                  <font-awesome-icon icon="external-link-alt" class="ml-1" />
                </small>
                <span :class="paquete.numeroHR ? 'text-primary' : 'text-muted user-select-none'">
                  {{ paquete.numeroHR ? paquete.numeroHR : 'Sin paquete ID'}}
                </span>
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pl-2 pl-sm-3 pl-lg-2">
                <small>Fecha recibido</small>
                {{ paquete.fecha.formatted }}
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pr-2">
                <small>Peso</small>
                <span title="Libras">
                  {{ paquete.peso }} lb
                </span>
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pl-2 pl-sm-3 pl-lg-2">
                <small>Alto</small>
                <span title="Pulgadas">
                  {{ paquete.alto }} in
                </span>
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pr-2">
                <small>Ancho</small>
                <span title="Pulgadas">
                  {{ paquete.ancho }} in
                </span>
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pl-2 pl-sm-3 pl-lg-2">
                <small>Largo</small>
                <span title="Pulgadas">
                  {{ paquete.largo }} in
                </span>
              </div>
              <mdb-btn
                flat
                dark-waves
                icon="user-check"
                :disabled="botonDeshabilitado"
                class="col-auto btn-bordeado mx-lg-2 mx-xl-1 p-2"
                @click="mostrarModalAsignarCliente = true
                  paqueteSeleccionadoId = paquete.id;
                  cliente = {};"
              >
                Agregar cliente
              </mdb-btn>
              <mdb-btn
                v-if="paquete.fotos && paquete.fotos.length"
                flat
                dark-waves
                icon="images"
                :disabled="botonDeshabilitado"
                class="col-auto btn-bordeado mx-lg-2 mx-xl-1 p-2"
                @click="paqueteSeleccionado = paquete; obtenerFotosPaquete(paquete.id)"
              >
                Fotos
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        id="consolidados"
        :class="['col-12 col-sm-10 col-md-7', tieneAccesoAPaquetesSinClientes ? 'col-lg-4' : 'col-lg-6', 'my-2 px-2 px-lg-1']"
      >
        <div class="columna consolidado">
          <header class="consolidado">Consolidados cargando</header>
          <CargandoVista
            v-if="$apolloData.queries.consolidadosCargando.loading"
            clase-adicional="vc-160px mb-3"
          />
          <div
            v-else-if="!$apolloData.queries.consolidadosCargando.loading
              && !(consolidadosCargando && consolidadosCargando.length)"
            class="text-muted text-center user-select-none py-3"
          >
            No hay consolidados cargando
          </div>
          <div
            v-else
            v-for="contenedor in consolidadosCargando"
            :key="contenedor.id"
            class="card"
          >
            <small>Destino</small>
            <span v-if="contenedor.destino">
              {{ contenedor.destino.ciudad.nombre }}
            </span>
            <span v-else class="text-muted user-select-none">Sin destino</span>
            <!-- Falta consultar el resto de la información -->
            <div class="row">
              <div class="col-6 col-sm-4 col-lg-6 pr-0">
                <small class="text-nowrap">Guía consolidado</small>
                {{ contenedor.master ? contenedor.master : 'Sin especificar'}}
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pl-2 pl-sm-3 pl-lg-2">
                <small>Envío</small>
                {{ ConsolidadoTipoEnvio[contenedor.tipo] }}
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pr-2">
                <small>Fecha carga</small>
                <span :class="{'text-muted user-select-none': !contenedor.fechaCarga.formatted}">
                  {{ contenedor.fechaCarga.formatted ? contenedor.fechaCarga.formatted : 'No disponible' }}
                </span>
              </div>
              <div class="col-6 col-sm-4 col-lg-6 pl-2 pl-sm-3 pl-lg-2">
                <small>Cargado</small>
                {{ contenedor.contienePaquetes ? 'Si' : 'No' }}
              </div>
              <div
                v-if="contenedor.peso"
                class="col-6 col-sm-4 col-lg-6 pr-2"
              >
                <small>Peso</small>
                <span title="Libras">
                  {{ contenedor.peso }} lb
                </span>
              </div>
              <div
                v-if="contenedor.volumen"
                :class="['col-6 col-sm-4 col-lg-6',
                  contenedor.peso ? 'pl-2 pl-sm-3 pl-lg-2' : 'pr-2']"
              >
                <small>Volumen</small>
                <span title="Pies cúbicos">
                  {{ contenedor.volumen }} ft<sup>3</sup>
                </span>
              </div>
              <div
                :class="['col-6 col-sm-4 col-lg-6', (contenedor.peso && contenedor.volumen)
                  || (!contenedor.peso && !contenedor.volumen) ? 'pr-2' : 'pl-2 pl-sm-3 pl-lg-2']"
              >
                <small>Pies cúbicos</small>
                {{ contenedor.piesCubicos }} ft<sup>3</sup>
              </div>
              <div
                :class="['col-6 col-sm-4 col-lg-6',(contenedor.peso && contenedor.volumen)
                  || (!contenedor.peso && !contenedor.volumen) ? 'pl-2 pl-sm-3 pl-lg-2' : 'pr-2']"
              >
                <small>Peso por volúmen</small>
                {{ contenedor.pesoVolumetrico }} vlbs
              </div>
            </div>
            <div class="row justify-content-center">
              <mdb-btn
                block
                flat
                dark-waves
                :disabled="botonDeshabilitado"
                class="col-10 my-1 p-2 btn-bordeado"
                title="Haz clic para cambiar el estatus del consolidado"
                @click="mostrarModalEditarContenedor = true;
                  consolidadoSeleccionado = JSON.parse(JSON.stringify(contenedor))"
              >
                {{ ConsolidadoEstatus[contenedor.estatus] }}
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ordenes"
        :class="['col-12 col-sm-10 col-md-7', tieneAccesoAPaquetesSinClientes ? 'col-lg-4' : 'col-lg-6', 'my-2 px-2']"
      >
        <div class="columna">
          <header>Órdenes de compra</header>
          <CargandoVista
            v-if="$apolloData.queries.ordenesCompra.loading"
            clase-adicional="vc-160px mb-3"
          />
          <div
            v-else-if="!$apolloData.queries.ordenesCompra.loading
              && !(ordenesCompra && ordenesCompra.length)"
            class="text-muted text-center user-select-none py-3"
          >
            No hay órdenes de compra
          </div>
          <div
            v-else
            v-for="orden in ordenesCompra"
            :key="orden.id"
            class="card"
          >
            <small>Cliente</small>
            {{ orden.cliente && orden.cliente.nombre ? orden.cliente.nombre : 'Sin cliente'  }}
            <small>Enlace</small>
            <a
              :href="orden.enlace"
              target="_blank"
              rel="noopener noreferrer"
              class="texto-ellipsis"
            >
              {{ orden.enlace }}
            </a>
            <div class="row">
              <div class="col-6">
                <small>Miniatura</small>
                <img
                  v-if="orden.miniatura && orden.miniatura.length"
                  :src="orden.miniatura"
                  alt="Miniatura del producto"
                  class="orden-miniatura"
                />
                <span v-else class="d-block mt-1 text-muted user-select-none fs-small75">
                  Sin miniatura asignada
                </span>
              </div>
              <div class="col-6 pl-2">
                <small>Fecha</small>
                {{ orden.fecha.formatted }}
              </div>
            </div>
            <small>Estatus</small>
            {{ OrdenEstatus[orden.estatus] }}
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Asignar Cliente -->
    <mdb-modal
      centered
      elegant
      :show="mostrarModalAsignarCliente"
      @close="mostrarModalAsignarCliente = false; cliente={}"
      @click="paqueteAsignarCliente"
    >
      <mdb-modal-header>
        <mdb-modal-title> Asignar cliente al paquete </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Cliente</header>
        <ClienteBuscador class="my-2" @cliente="cliente = $event" />
        <div class="text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="paqueteAsignarCliente"
          >
            Asignar cliente
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal Editar Estatus Contenedor -->
    <mdb-modal
      centered
      elegant
      :show="mostrarModalEditarContenedor"
      @close="mostrarModalEditarContenedor = false"
    >
    <mdb-modal-header>
        <mdb-modal-title>
          Estatus del consolidado
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Estatus</header>
        <div class="md-form md-outline outline-select my-2">
          <select
            v-model="consolidadoSeleccionado.estatus"
            class="custom-select"
          >
            <option class="d-none" disabled value="">
              Seleccione
            </option>
            <option
              v-for="(value, key) in ConsolidadoEstatus"
              :key="value"
              :value="key"
            >
            {{ value }}
            </option>
          </select>
        </div>
        <div class="text-center">
          <mdb-btn
              color="primario"
              :icon="botonDeshabilitado ? 'circle-notch' : 'sync'"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado"
              class="mt-4"
              @click="consolidadoEditarEstatus"
          >
            Actualizar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Galería de fotos del paquete -->
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="mostrarModalVerFotos"
      @close="mostrarModalVerFotos = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Galería de fotos
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="cargandoFotos">
          <CargandoVista
            class="altura-spinner"
            clase-adicional="vc-10px"
          />
        </div>
        <div v-if="paqueteSeleccionado && paqueteSeleccionado.fotos && paqueteSeleccionado.fotos.length && !cargandoFotos">
          <ul class="listado-fotos">
            <li
              v-for="(foto, iFoto) in paqueteSeleccionado.fotos"
              :key="iFoto"
              class="foto-contenedor"
            >
              <div class="foto">
                <img
                  v-lazy="`${endpointPublicBackend}${foto.url}`"
                  :alt="`Imagen nro ${iFoto + 1} del paquete`"
                  style="height: 100px"
                  @click="abrirGaleriaEn(iFoto)"
                >
              </div>
            </li>
          </ul>
        </div>
        <div
          v-if="paqueteSeleccionado && paqueteSeleccionado.fotos && !paqueteSeleccionado.fotos.length && !cargandoFotos"
          class="vertical-centered vc-160px user-select-none"
        >
          <font-awesome-icon
            :icon="['fas', 'images']"
            class="fa-5x my-4"
          />
          <p class="h4-responsive text-center mb-4">
            No hay fotos del paquete
          </p>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <LightBox
      v-if="mostrarModalVerFotos && paqueteSeleccionado && paqueteSeleccionado.fotos && paqueteSeleccionado.fotos.length"
      ref="lightbox"
      :media="fotosDelPaquete"
      :show-light-box="false"
    />
    <!-- Fin galería de fotos -->

    <!-- Modal Formulario paquete  / reempaque -->
    <FormularioPaquete
      titulo="Datos del paquete"
      :mostrar-modal="mostrarFormularioPaquete"
      :esResumen="true"
      @cerrar="mostrarFormularioPaquete = $event"
      @sinCliente="PaqueteAgregarSinCliente($event);"
      @alertaMensaje="alertaMensaje=$event"
    />

    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>

<script>
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import LightBox from 'vue-image-lightbox';
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import ClienteBuscador from "@/components/ClienteBuscador.vue";
import FormularioPaquete from "@/components/FormularioPaquete.vue";
import paquetesSinClienteGql from "@/graphql/paquetesSinCliente.gql";
import paqueteAsignarClienteGql from "@/graphql/paqueteAsignarCliente.gql"
import resumenRapidoAdminGql from "@/graphql/resumenRapidoAdmin.gql"
import resumenRapidoAgenciaGql from "@/graphql/resumenRapidoAgencia.gql"
import resumenRapidoOpPuertoGql from "@/graphql/resumenRapidoOpPuerto.gql"
import resumenRapidoOpGql from "@/graphql/resumenRapidoOp.gql"
import resumenRapidoOpAlmacenGql from "@/graphql/resumenRapidoOpAlmacen.gql"
import consolidadosPorEstatusGql from "@/graphql/consolidadosPorEstatus.gql";
import consolidadoEditarGql from "@/graphql/consolidadoEditar.gql"
import ordenesCompraGql from "@/graphql/ordenesCompra.gql";
import { ConsolidadoTipoEnvio, ConsolidadoEstatus } from "@/constantes/consolidados.js";
import {endpointPublicBackend} from "@/constantes/paquetes.js"
import gql from "graphql-tag";
import { OrdenEstatus } from "@/constantes/ordenes.js";
import obtenerFotosGql from "@/graphql/obtenerFotos.gql";
import { leerRoles } from "@/utils/datosToken.js";
import { mostrarSoloDosDecimales } from "@/funciones/funciones.js"

export default {
  name: "Resumen",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    ClienteBuscador,
    FormularioPaquete,
    LightBox,
    AlertaMensaje,
    CargandoVista,
  },
  data() {
    return {
      endpointPublicBackend,
      mostrarTarjetas: false,
      rol: leerRoles(),
      mostrarSoloDosDecimales,
      alertaMensaje: {contenido: ''},
      consolidadoSeleccionado: {},
      botonDeshabilitado: false,
      cliente: {}, // Usado en ModalAsignarCliente
      paqueteSeleccionadoId: '',
      mostrarModalAsignarCliente: false,
      mostrarModalEditarContenedor: false,
      mostrarModalEditarOrden: false,
      mostrarFormularioPaquete: false,
      OrdenEstatus,
      ConsolidadoTipoEnvio,
      ConsolidadoEstatus,
      // Fotos
      paqueteSeleccionado: {},
      mostrarModalVerFotos: false,
      cargandoFotos: false,
      // Otros
      filtrarPorEstatus: {
        filter: {
          estatus: "Cargando",
        }
      },
      filtrarOrdenes:{
        filter: {
          estatus: "EnEspera",
        },
      }
    };
  },
  computed:{
    fotosDelPaquete() {
      if (this.paqueteSeleccionado.fotos && this.paqueteSeleccionado.fotos.length > 0) {
        const endpoint = this.endpointPublicBackend;
        return this.paqueteSeleccionado.fotos.map((foto)=> 
            ({
            thumb: `${endpoint}${foto.url}`,
            src: `${endpoint}${foto.url}`,
          })
        );
      }
      return [""]
    },
    obtenerFechaBusquedaPaquetes(){
      //Fecha fija para obtener paquetes pendientes de pago
      return new Date('Jul 31 2022').toISOString().substring(0,10)
    },
    paquetesEnCaracas(){
      return this.resumenRapido.ListoDespacho + this.resumenRapido.EsperaCaracas
    },
    tieneAccesoAPaquetesSinClientes() {
      if (
        this.rol.includes("Admin") ||
        this.rol.includes("OpAlmacen") ||
        (this.rol.includes("OpPuerto") && this.rol.includes("OpAlmacen")) ||
        this.rol.includes("Agencia")
      ) {
        return true;
      }
      return false;
    },
    tieneAccesoACrearPaquetes() {
      if (
        this.rol.includes("Admin") ||
        this.rol.includes("OpAlmacen") ||
        (this.rol.includes("OpPuerto") && this.rol.includes("OpAlmacen"))
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    abrirGaleriaEn(foto) {
      this.$refs.lightbox.showImage(foto)
    },
    obtenerFotosPaquete(paqueteId){
      this.cargandoFotos = true
      this.mostrarModalVerFotos=true;
      this.$apollo.query({
        query:gql`${obtenerFotosGql}`,
        variables:{
          grafoId:paqueteId
        },
        update:data=>data.obtenerFotos,
        fetchPolicy:"network-only"
      }).then(res=>{
        this.paqueteSeleccionado.fotos = res.data.obtenerFotos
        this.cargandoFotos = false
      }).catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.cargandoFotos = false
        this.mostrarModalVerFotos=false

      })
    },
    queryResumenRapido(){
      if(this.rol.includes('Admin')){
        return resumenRapidoAdminGql
      }
       if(this.rol.includes('Agencia')){
        return resumenRapidoAgenciaGql
      }
       if(this.rol.includes('OpPuerto') && this.rol.includes('OpAlmacen')){
        return resumenRapidoOpGql
      }
       if(this.rol.includes('OpPuerto')){
        return resumenRapidoOpPuertoGql
      }
       if(this.rol.includes('OpAlmacen')){
        return resumenRapidoOpAlmacenGql
      }
    },
    PaqueteAgregarSinCliente(paquete){
      this.paquetesSinCliente.push(paquete)
    },
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
  variablesResumenRapido() {
      let variables = {};
      if (this.rol.includes("Admin") || this.rol.includes("Agencia")) {
        Object.assign(variables, {
          fechaActual: this.obtenerFechaBusquedaPaquetes,
        });
        return variables;
      } 
      return variables;
    },
    actualizarConsultas() {
      this.$apollo.queries.resumenRapido.refetch();
      this.$apollo.watchQuery({
            query: paquetesSinClienteGql,
            update: (data) => data.Paquete,
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Paquete){
                this.paquetesSinCliente = data.Paquete;
              }
            },
            error: () => {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando los paquetes',
                tipo: 'error',
              };
            }
          })
      this.$apollo.watchQuery({
            query: consolidadosPorEstatusGql,
            update: (data) => data.Consolidado,
            variables: this.filtrarPorEstatus,
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Consolidado){
                this.consolidadosCargando = data.Consolidado;
              }
            },
            error: () => {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando los consolidados',
                tipo: 'error',
              };
            }
          })
      this.$apollo.watchQuery({
            query: ordenesCompraGql,
            update: (data) => data.Orden,
            variables: this.filtrarOrdenes,
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Orden){
                this.ordenesCompra = data.Orden;
              }
            },
            error: () => {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando las ordenes de compra',
                tipo: 'error',
              };
            }
          })
    },
    paqueteAsignarCliente() {
      if (!this.cliente.id){
        return this.alertaMensaje = {
            contenido:  'Por favor selecciona un cliente',
            tipo: 'advertencia',
          };
      }
      if (!this.cliente.casillero){
        return this.alertaMensaje = {
          contenido: "El cliente debe contar con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia",
          tipo: 'advertencia',
        };
      }
      this.botonDeshabilitado = true;
      delete this.cliente.__typename;
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteAsignarClienteGql}
          `,
          variables: {
            paquetesId: [this.paqueteSeleccionadoId],
            personaId: this.cliente.id,
          },
          update: (store, {data: {paqueteAsignarCliente}}) => {
            const msg = paqueteAsignarCliente.codigo;
            switch (msg) {
              case "Correcto":
                this.alertaMensaje = {
                  contenido: 'Se ha asignado un cliente al paquete correctamente',
                  tipo: 'correcto',
                };
                break;
              case "Fallido":
                this.alertaMensaje = {
                    contenido:  'Ha ocurrido un error asignando cliente al paquete.'
                    +' Por favor intenta de nuevo',
                    tipo: 'error',
                  };
                this.botonDeshabilitado = false;
                return;
              default:
                this.mostrarMensajeEnCasoDefault();
                this.botonDeshabilitado = false;
                return;
            }
            const data = store.readQuery({
              query: paquetesSinClienteGql,
            });
            // Se remueve el paquete
            data.Paquete = data.Paquete.filter((e) => {
              return !this.paqueteSeleccionadoId.includes(e.id);
            });

            store.writeQuery({
              query: paquetesSinClienteGql,
              data,
            });
            this.mostrarModalAsignarCliente = false;
            this.botonDeshabilitado = false;
            this.$apollo.queries.resumenRapido.refetch();
          },
          optimisticResponse: {
            __typename: 'Mutation',
              paqueteAsignarCliente: {
              __typename: 'Resultado',
              codigo: 'Correcto',
            },
          },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
      })
    },
    consolidadoEditarEstatus() {
      if(this.consolidadoSeleccionado.estatus === "Cargando"){
        return this.alertaMensaje = {
          contenido: 'Por favor selecciona un estatus diferente',
          tipo: 'advertencia',
        };
      }
      if(!this.consolidadoSeleccionado.contienePaquetes){
        return this.alertaMensaje = {
          contenido: 'El consolidado se encuentra sin paquetes dentro. Esta acción no se puede realizar',
          tipo: 'advertencia',
        };
      }
      this.botonDeshabilitado = true;
      // Seleccionados datos requeridos en consolidadoEntrada + estatus modificado
      const consolidado = {
        id: this.consolidadoSeleccionado.id,
        estatus: this.consolidadoSeleccionado.estatus,
        tipo: this.consolidadoSeleccionado.tipo,
        master: this.consolidadoSeleccionado.master
      };
      delete this.consolidadoSeleccionado.__typename;
      delete this.consolidadoSeleccionado.fechaCarga.__typename;
      this.$apollo.mutate({
        mutation: gql`${consolidadoEditarGql}`,
        variables: {
          consolidado,
        },
        update: (store, {data: {consolidadoEditar}}) => {
          const codigo = consolidadoEditar.codigo;
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'El consolidado ha sido actualizado satisfactoriamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando el consolidado. Por favor intenta de nuevo',
                tipo: 'error',
              };
              return;
            default:
              this.mostrarMensajeEnCasoDefault();
              return;
          }
          const data = store.readQuery({
            query: consolidadosPorEstatusGql,
            variables: this.filtrarPorEstatus,
          });
          // Se remueve el consolidado
          data.Consolidado = data.Consolidado.filter((e) => {
            return !this.consolidadoSeleccionado.id.includes(e.id);
          });

          store.writeQuery({
            query: consolidadosPorEstatusGql,
            variables: this.filtrarPorEstatus,
            data,
          });
          this.mostrarModalEditarContenedor = false;
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: 'Se ha actualizado el estatus exitosamente',
            tipo: 'correcto',
          };
        },
        optimisticResponse: {
          __typename: 'Mutation',
            consolidadoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
      })
    },
  },
  apollo: {
    paquetesSinCliente() {
      return {
        query: paquetesSinClienteGql,
        update: (data) => data.Paquete,
        fetchPolicy: "cache-and-network",
      };
    },
    resumenRapido() {
      return {
        query: this.queryResumenRapido(),
        update: (data) => data,
        fetchPolicy: "cache-and-network",
        variables:this.variablesResumenRapido()
      };
    },
    consolidadosCargando() {
      return {
        query: consolidadosPorEstatusGql,
        variables: this.filtrarPorEstatus,
        update: (data) => data.Consolidado,
        fetchPolicy: "cache-and-network",
      };
    },
    ordenesCompra() {
      return {
        query: ordenesCompraGql,
        update: (data) => data.Orden,
        fetchPolicy: "cache-and-network",
        variables: this.filtrarOrdenes,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-descripcion {
  border: 1px solid $gris-tr-borde;
  border-radius: 8px;
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: .5rem 0;
  padding: .75rem 1rem;

  @media screen and (min-width: 768px) {padding: 0.75rem .5rem;}

  .titulo {
    font-size: .9rem;
    font-weight: 300;
    margin-bottom: .5rem;

    .clave {font-weight: 500;}
  }
  .fila-data {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    img {
      color: $gris-thead-borde;
      font-size: .68rem;
      font-style: italic;
      height: 45px;
      line-height: 1.2;
      text-transform: uppercase;
      width: auto;
    }

    p {
      color: $terciario;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 0;
      text-align: right;

      &.sin-datos {
        color: $gris-placeholder;
        font-size: .9rem;
        font-style: italic;
        font-weight: 300;
      }
    }
  }
}

.tab-lista {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  background-color: white;
  margin: 0 -.5rem;
  position: sticky;
  top: 0;
  z-index: 3;

  .tab-item {
    color: $encabezado-1;
    cursor: pointer;
    font-size: .9rem;
    font-weight: 300;
    padding: .5rem;
  }
}
.columna {
  background-color: lighten($terciario, 75%); /* $terciario + blanco */
  border-radius: 0.3rem;
  padding: 0 0.5rem 1rem;
  position: relative;

  &.consolidado {
    @media screen and (max-width: 991px) {
      background-color: lighten($primario, 35%); /* $primario + blanco */
    }
  }

  header {
    background-color: lighten($terciario, 75%);; /* Mismo color de .columna */
    border-radius: 0.3rem 0.3rem 0 0;
    color: $encabezado-3;
    font-weight: 500;
    margin: 0 -0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    position: sticky;
    user-select: none;
    top: 37px;
    z-index: 1;

    @media screen and (min-width: 576px) {padding-left: 15px;}
    @media screen and (min-width: 992px) {top: -1px;}

    &.consolidado  {
      @media screen and (max-width: 991px) {
        background-color: lighten($primario, 35%); /* $terciario + blanco */
      }
    }
  }

  & > div[class*="card"] {
    cursor: default;
    font-size: 0.95rem;
    margin: 0.5rem 0;
    padding: 0.65rem 0.5rem;

    &:first-of-type {
      margin-top: 0.25rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    small {
      color: $gris-label;
      display: block;
      margin-bottom: -0.25rem;
      user-select: none;
    }
    .orden-miniatura {
      font-size: .68rem;
      height: 40px;
      margin: .25rem 0;
      width: 40px;
    }
  }
}
</style>